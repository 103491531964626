import { graphql } from "gatsby"
import React from "react"
import Layout from "../Page"
import Register from "../../components/component-register"

const RegisterPage = ({ data: { silverStripeDataObject } }) => {
  const {
    bannerTitle,
    bannerSubTitle,
    bannerImageURL,
    metaTags,
    customCSS
  } = silverStripeDataObject.Page
  const pageData = {
        pageClass: 'ContactPage', 
        bodyClass: '', 
        pageTitle: '',
        metaTags: metaTags,      
        customCSS: customCSS,
    }
  return (
    <Layout pageData={pageData}>
      <Register />
    </Layout>
  )
}
export default RegisterPage
export const pageQuery = graphql`
  query($link: String!) {
    silverStripeDataObject(link: { eq: $link }) {
      Page {
        bannerTitle
        bannerSubTitle
        bannerImageURL
        metaTags
        customCSS
      }
    }
  }
`
